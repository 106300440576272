.login-badge {
  top: 0;
  left: 0;
  position: absolute;

  &__background {
    z-index: -1;
    position: absolute;
    width: 320px;
    height: 320px;
    left: -57px;
    top: -100px;

    background: #FFFFFF;
    border-radius: 30px;
    transform: rotate(-20deg);
  }

  &__content {

  }

  &__image {
    margin: 50px;
    height: 100px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}